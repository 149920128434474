import { Button, Toolbar, Typography } from "@mui/material";
import React, { useState } from "react";
import { http } from "../../../helper/http";
import { TagApiResponse } from "../../../types/TagTypes";
import AddIcon from "@mui/icons-material/Add";
import AddTemplateForm from "./AddTemplateForm";
import { fetchAllCustomer } from "../../../common-function/CommonFunction";
import { setAllCustomer } from "../../../app-context/customer-context/CustomerAction";
import { useCustomerState } from "../../../app-context/customer-context/CustomerState";

interface EnhancedTableToolbarProps {
  onFilter: (value: string, filterOption: string) => void;
}
const baseUrl = process.env.REACT_APP_BASE_URL;

function EnhancedTableToolbar({ onFilter }: EnhancedTableToolbarProps) {
  const [formModalIsOpen, setFormModalIsOpen] = useState(false);
  const { dispatch: customerDispatch } = useCustomerState();

  const toggleForm = async () => {
    setFormModalIsOpen((prevVal) => !prevVal);
    try {
      const resp = (await fetchAllCustomer()) as any;
      setAllCustomer(customerDispatch, resp.customers);
    } catch (error) {
      console.error(error);
    }
  };

  const handleFilterChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;
    let resp;
    try {

      if (value === "") {
        resp = (await http(baseUrl + "/tags?page=1")) as TagApiResponse;
      } else {
        resp = (await http(
          baseUrl + "/tags?searchTag=" + value
        )) as TagApiResponse;
      }
      // getTagPageClick(dispatch, resp.tags);
    } catch (error) {
      console.log("no device found");
    }
  };

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
      }}
    >
      <Typography
        sx={{
          flex: "1 1 100%",
          fontWeight: 700,
          fontSize: [
            "text-sm",
            "text-md",
            "text-lg",
            "text-xl",
            "text-2xl",
            "text-3xl",
          ],
        }}
        variant="h6"
        id="tableTitle"
        component="div"
        className="drop-shadow-[2px_2px_var(--tw-shadow-color)] "
      >
        Templates
      </Typography>

      <div className="flex flex-col sm:flex-row items-center space-y-2 sm:space-y-0 sm:space-x-2">
        <Button
          variant="contained"
          sx={{
            backgroundColor: "teal",
            color: "white",
            height: 32,
            fontWeight: "bold",
            fontSize: "1rem",
            transition: "background-color 200ms",
            "&:hover": {
              backgroundColor: "teal",
            },
          }}
          onClick={toggleForm}
        >
          <AddIcon />
          ADD
        </Button>
        <input
          type="text"
          placeholder="Search"
          onChange={handleFilterChange}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              {
              }
            }
          }}
          className="border border-black rounded p-1 w-full sm:w-auto"
        />
      </div>
      {formModalIsOpen && (
        <AddTemplateForm
          toggleForm={formModalIsOpen}
          toggleFormFun={toggleForm}
          data={[]}
          uniqueId="ADD"
        />
      )}
    </Toolbar>
  );
}

export default EnhancedTableToolbar;
