import  { useEffect, useState, useMemo } from "react";
import Table from "@mui/material/Table";
import Box from "@mui/material/Box";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import EnhancedTableHead from "./EnhancedTableHead";
import EnhancedTableToolbar from "./EnhancedTableToolbar";
import { ApiResponseTag, Tag, TagApiResponse } from "../../../types/TagTypes";
import { useTagState } from "../../../app-context/tags-context/TagState";
import LongMenu from "../../../utils/longMenu/LongMenu";
import { http } from "../../../helper/http";
import AddTagForm from "./AddTagFrom";
import { ConfirmModal } from "../../../utils/alertui/ConfirmModal";
import { toast } from "react-toastify";
import { getTagPageClick, setTag } from "../../../app-context/tags-context/TagAction";
import { useCustomerState } from "../../../app-context/customer-context/CustomerState";
import { fetchAllCustomer } from "../../../common-function/CommonFunction";
import { setAllCustomer } from "../../../app-context/customer-context/CustomerAction";

const baseUrl = process.env.REACT_APP_BASE_URL;
export default function Tags() {
  const [page] = useState(0);
  const [rowsPerPage] = useState(10);
  const [updatedRows, setUpdatedRows] = useState<Tag[]>([]);
  const [tagData, setTagData] = useState<Tag[]>([]);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deleteId, setDeleteId] = useState<number | null>(null);
  const {  dispatch: customerDispatch } = useCustomerState();



  const toggleTagForm = () => {
    setEditModal((prevVal) => !prevVal);
  };


  const { tags, dispatch } = useTagState()

  useEffect(() => {
    setUpdatedRows(tags.tags);
  }, [tags, tags.previousPage]);





  const handleMenuOptionClick = async (option: string, id: number) => {
    try {
      switch (option) {
        case "Delete":      
          setShowConfirmModal(true);
          setDeleteId(id);
          break;
        case "Edit":
          const resp = (await fetchAllCustomer()) as any;
          setAllCustomer(customerDispatch, resp.customers);
          const editResp = (await http(
            baseUrl + `/tags?id=${id}`
          )) as TagApiResponse;
          setTagData([editResp.tags]);
          toggleTagForm();
          break;
        case "View":
          // const viewResp = (await http(
          //   baseUrl + `/device?id=${id}`
          // )) as DeviceApiResponse;
          // setDeviceData([viewResp.devices]);
          // toggleViewForm();
          break;
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleConfirmDelete = async () => {
    if (deleteId !== null) {
      try {
        // console.log(deleteId);
        
        const deleteResp = (await http(
          baseUrl + `/tags?id=${deleteId}`,
          "DELETE"
        )) as TagApiResponse;
        toast.success(deleteResp.message);
        if (tags.tags.length === 1) {
          setTag(dispatch, 1);
        } else {
          setTag(dispatch, tags.currentPage);
        }
      } catch (err) {
        console.error(err);
      }
      setShowConfirmModal(false);
    }
  };


  const emptyRows = 10 - updatedRows.length;
  const visibleRows = useMemo(
    () =>
      updatedRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [page, rowsPerPage, updatedRows]
  );
  const handlePageChange = async (newPage: number) => {
    const direction = newPage > tags.currentPage - 1 ? "next" : "previous";
    const page = +tags.currentPage;
    try {
      const resp = (await http(
        baseUrl + `/tags?page=${direction === "next" ? page + 1 : page - 1}`
      )) as ApiResponseTag;
      getTagPageClick(dispatch, resp.tags);
    } catch (err) {
      console.error(err);
    }
  };

  // const handleSwitchToggle = async (id: number, status?: boolean) => {
  //   try {
  //     const resp = (await http(
  //       baseUrl + `/tags?id=${id}&status=${!status}`,
  //       "PATCH"
  //     )) as TagApiResponse;
  //     updateTagStatus(dispatch, { id, status: !status });
  //     toast.success(resp.message);
  //   } catch (err) {
  //     console.error(err);
  //   }
  // };
  return (
    <>
      <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <Paper sx={{ width: "99%", mb: 2, mt: 2 }}>
          <EnhancedTableToolbar onFilter={() => { }} />
          <div className="h-[72vh] overflow-y-auto">
            <TableContainer>
              {showConfirmModal && (
                <ConfirmModal
                  title="Confirmation"
                  message="Are you sure you want to delete?"
                  onConfirm={handleConfirmDelete}
                  onCancel={() => {
                    setShowConfirmModal(false);
                  }}
                  isVisible={showConfirmModal}
                />
              )}
              <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size="medium"
              >
                {editModal && (
                  <AddTagForm
                    uniqueId="EDIT"
                    data={tagData}
                    toggleForm={editModal}
                    toggleFormFun={toggleTagForm}
                  />
                )}
                <EnhancedTableHead />
                <TableBody>
                  {visibleRows.map((row: Tag) => {
                    return (
                      <TableRow
                        hover
                        className="cursor-pointer transition-all hover:bg-gray-100"
                        key={row.id}
                      >
                        <TableCell align="center" style={{ textAlign: "center" }}>
                          {row.tagname}
                        </TableCell>
                        <TableCell align="center" style={{ textAlign: "center" }}>
                          {row.unit}
                        </TableCell>
                        <TableCell align="center" style={{ textAlign: "center" }}>
                          {row.factorialvalue}
                        </TableCell>
                        <TableCell align="center" style={{ textAlign: "center" }}>
                          {row?.Customer?.name || "NA"}
                        </TableCell>
                        {/* <TableCell
                          className="text-center"
                          style={{ textAlign: "center" }}
                        >
                          <Switch
                            key={row.id} // Ensure that row.id is unique for each row
                            checked={Boolean(row.status !== undefined ? row.status : false)}
                            color="primary"
                            onChange={() =>
                              handleSwitchToggle(row.id || 0, Boolean(row.status))
                            }
                          />
                        </TableCell> */}
                        <TableCell
                          className="text-center"
                          style={{ textAlign: "center" }}
                        >
                          <LongMenu
                            options={["Edit", "Delete"]}
                            onOptionClick={handleMenuOptionClick}
                            id={row.id}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: 53 * emptyRows,
                      }}
                    >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <TablePagination
            rowsPerPageOptions={[]}
            component="div"
            count={tags?.totalItems || 0}
            rowsPerPage={10}
            page={Math.max(
              0,
              Math.min(tags?.currentPage - 1, tags?.totalPages - 1)
            )}
            onPageChange={(event, newPage) => handlePageChange(newPage)}
            className="bg-gray-100"
          />
        </Paper>
      </Box>
    </>
  );
}
