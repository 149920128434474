// import {
//   Button,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogTitle,
//   InputLabel,
//   MenuItem,
//   Select,
// } from "@mui/material";
// import React, { useEffect, useState } from "react";
// import { Tag } from "../../../types/TagTypes";
// import { http } from "../../../helper/http";
// import { useTagState } from "../../../app-context/tags-context/TagState";
// import { Template } from "../../../types/TemplateType";
// import { useTemplateState } from "../../../app-context/template-context/TemplateState";
// import { addNewTagGroup, updateTagGroup } from "../../../app-context/taggroup-context/TagGroupAction";
// import { useTagGroupState } from "../../../app-context/taggroup-context/TagGroupState";
// import { ApiResponseForCreateTagGroup, FetchedTagsGroupData, TagsGroupAttributes } from "../../../types/TagGroup";
// import { toast } from "react-toastify";

// interface AddTagFormModalProps {
//   toggleForm: boolean;
//   toggleFormFun: () => void;
//   data: FetchedTagsGroupData[];
//   uniqueId: string;
// }

// let id: number | 0;
// const baseUrl = process.env.REACT_APP_BASE_URL;

// const AddTagGroupForm: React.FC<AddTagFormModalProps> = ({
//   toggleForm,
//   toggleFormFun,
//   data,
//   uniqueId,
// }) => {

//   const [template, setTemplate] = useState<Template[]>([]);
//   const [selectedTemplate, setSelectedTemplate] = useState("");
//   const [tag, setTag] = useState<Tag[]>([]);
//   const [selectedTag, setSelectedTag] = useState("");

//   const { templates } = useTemplateState()
//   const { tags } = useTagState()
//   const { tagsGroups, dispatch } = useTagGroupState();





//   useEffect(() => {
//     setTemplate(templates.templates);
//     setTag(tags.tags);

//     if (uniqueId === "EDIT" && data) {
//       const tagToUse = Array.isArray(data) ? data[0] : data;
//       id = tagToUse.tagsGroup.id;
//       setSelectedTag(tagToUse.tagsGroup.Tag.id.toString()); // Convert to string
//       setSelectedTemplate(tagToUse.tagsGroup.Template.id?.toString() || "");
//     }
//   }, [uniqueId, data]);


//   const handleSubmit = async () => {
//     const data = {
//       tagid: selectedTag,
//       templateid: selectedTemplate
//     };
//     try {
//       if (uniqueId === "ADD") {
//         const resp = (await http(
//           baseUrl + `/tag-groups`,
//           "POST",
//           data
//         ) as ApiResponseForCreateTagGroup);
//         addNewTagGroup(dispatch, { taggroup: resp })
//         toast.success(resp.message);

//       } else {
//         const resp = (await http(
//           baseUrl + `/tag-groups?id=${id}`,
//           "PUT",
//           data
//         ) as ApiResponseForCreateTagGroup);
//         const tagnames = tag.find(tag => tag.id === +selectedTag)?.tagname || "";
//         const templatenames = template.find(temp => temp.id === +selectedTemplate)?.templatename || "";     
//         updateTagGroup(dispatch, { id, tagname: tagnames, templatename: templatenames });
//         toast.success(resp.message);

//       }
//     } catch (err) {
//       console.log(err);
//     }
//     toggleFormFun();

//   }


//   return (
//     <>
//       {/* Popup onclick of Add button */}
//       <Dialog open={toggleForm} onClose={toggleFormFun}>
//         <DialogTitle>
//           {" "}
//           {uniqueId === "ADD" ? "Add TagGroup" : "Update TagGroup"}
//         </DialogTitle>
//         <DialogContent>
//           <InputLabel id="selectunit">Select the Tag</InputLabel>
//           <Select
//             labelId="selectedtag"
//             id="select-tag"
//             name={selectedTag}
//             value={selectedTag}
//             label="Select Tag"
//             onChange={(e) => setSelectedTag(e.target.value)}
//             sx={{ width: "100%" }}
//           >
//             {tag.map((tag) => (
//               <MenuItem key={tag.id}  value={tag.id}>
//                 {tag.tagname}
//               </MenuItem>
//             ))}
//           </Select>

//           <InputLabel id="selectunit">Select the Template</InputLabel>
//           <Select
//             labelId="selectedtemplate"
//             id="select-template"
//             name={selectedTemplate}
//             value={selectedTemplate}
//             label="Select Template"
//             onChange={(e) => setSelectedTemplate(e.target.value)}
//             sx={{ width: "100%" }}
//           >
//             {template.map((template) => (
//               <MenuItem key={template.id} value={template.id}>
//                 {template.templatename}
//               </MenuItem>
//             ))}
//           </Select>



//         </DialogContent>
//         <DialogActions>
//           <Button onClick={toggleFormFun}>Cancel</Button>
//           <Button onClick={handleSubmit} color="primary">
//             {uniqueId === "ADD" ? "Save" : "Update"}
//           </Button>
//         </DialogActions>
//       </Dialog>
//     </>
//   );
// };

// export default AddTagGroupForm;

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Tag } from "../../../types/TagTypes";
import { http } from "../../../helper/http";
import { useTagState } from "../../../app-context/tags-context/TagState";
import { Template } from "../../../types/TemplateType";
import { useTemplateState } from "../../../app-context/template-context/TemplateState";
import { addNewTagGroup, updateTagGroup } from "../../../app-context/taggroup-context/TagGroupAction";
import { useTagGroupState } from "../../../app-context/taggroup-context/TagGroupState";
import { ApiResponseForCreateTagGroup, FetchedTagsGroupData } from "../../../types/TagGroup";
import { toast } from "react-toastify";

interface AddTagFormModalProps {
  toggleForm: boolean;
  toggleFormFun: () => void;
  data: FetchedTagsGroupData[];
  uniqueId: string;
}

let id: number | 0;
const baseUrl = process.env.REACT_APP_BASE_URL;

const AddTagGroupForm: React.FC<AddTagFormModalProps> = ({
  toggleForm,
  toggleFormFun,
  data,
  uniqueId,
}) => {

  const [template, setTemplate] = useState<Template[]>([]);
  const [selectedTemplate, setSelectedTemplate] = useState("");
  const [tag, setTag] = useState<Tag[]>([]);
  const [selectedTag, setSelectedTag] = useState("");

  const { templates } = useTemplateState()
  const { tags } = useTagState()
  const {  dispatch } = useTagGroupState();





  useEffect(() => {
    setTemplate(templates.templates);
    setTag(tags.tags);
    if (uniqueId === "EDIT" && data) {
      const tagToUse = Array.isArray(data) ? data[0] : data;
      id = tagToUse.tagsGroup.id;
      setSelectedTag(tagToUse.tagsGroup.Tag.id.toString()); // Convert to string
      setSelectedTemplate(tagToUse.tagsGroup.Template.id?.toString() || "");
      console.log(tags.tags);

    }
  }, [uniqueId, data, tags, templates]);


  const handleSubmit = async () => {
    const data = {
      tagid: selectedTag,
      templateid: selectedTemplate
    };
    try {
      if (uniqueId === "ADD") {
        const resp = (await http(
          baseUrl + `/tag-groups`,
          "POST",
          data
        ) as ApiResponseForCreateTagGroup);
        addNewTagGroup(dispatch, { totalitems: resp.tagGroupData.totalItems, taggroup: resp })
        toast.success(resp.message);

      } else {

        const resp = (await http(
          baseUrl + `/tag-groups?id=${id}`,
          "PUT",
          data
        ) as ApiResponseForCreateTagGroup);
        const tagnames = tag.find(tag => tag.id === +selectedTag)?.tagname || "";
        const templatenames = template.find(temp => temp.id === +selectedTemplate)?.templatename || "";
        updateTagGroup(dispatch, { id, tagname: tagnames, templatename: templatenames });
        toast.success(resp.message);

      }
    } catch (err: any) {
      console.error(err);
      toast.error(err);
    }
    toggleFormFun();

  }


  return (
    <>
      {/* Popup onclick of Add button */}
      <Dialog open={toggleForm} onClose={toggleFormFun}>
        <DialogTitle>
          {" "}
          {uniqueId === "ADD" ? "Add TagGroup" : "Update TagGroup"}
        </DialogTitle>
        <DialogContent>
          <InputLabel id="selectunit">Select the Tag</InputLabel>
          <Select
            labelId="selectedtag"
            id="select-tag"
            name={selectedTag.toString()}
            value={selectedTag.toString()}
            label="Select Tag"
            onChange={(e) => setSelectedTag(e.target.value as string)}
            sx={{ width: "100%" }}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 300, // Adjust the maxHeight as needed
                },
              },
            }}
          >
            {tag.map((tag) => (
              <MenuItem key={tag.id} value={tag.id} style={{paddingLeft: "20px", display: "block", marginBottom: "5px"}}>
                {tag.tagname}
              </MenuItem>
            ))}
          </Select>

          <InputLabel id="selectunit">Select the Template</InputLabel>
          <Select
            labelId="selectedtemplate"
            id="select-template"
            name={selectedTemplate.toString()}
            value={selectedTemplate.toString()}
            label="Select Template"
            onChange={(e) => setSelectedTemplate(e.target.value as string)}
            sx={{ width: "100%" }}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 300, // Adjust the maxHeight as needed
                },
              },
            }}
          >
            {template.map((template) => (
              <MenuItem key={template.id} value={template.id} style={{paddingLeft: "20px",  display: "block", marginBottom: "5px"}}>
                {template.templatename}
              </MenuItem>
            ))}
          </Select>



        </DialogContent>
        <DialogActions>
          <Button onClick={toggleFormFun}>Cancel</Button>
          <Button onClick={handleSubmit} color="primary">
            {uniqueId === "ADD" ? "Save" : "Update"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddTagGroupForm;

