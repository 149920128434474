import React, { useState, useEffect, useRef } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { http } from "../../helper/http";
import { useUserState } from "../../app-context/auth-context/AuthState";
import { toast } from "react-toastify";
import { TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";

const baseUrl = process.env.REACT_APP_BASE_URL;

const MeterReadingData1 = () => {
  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);
  const [reportFormat, setReportFormat] = useState("excel");
  const [method, setMethod] = useState("manually");
  const [selectedDevices, setSelectedDevices] = useState<any[]>([]);
  const [selectedDeviceTemplate, setSelectedDevicesTemplate] = useState<any[]>([]);
  const { users } = useUserState();
  const [devices, setDevices] = useState<any[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredDevices, setFilteredDevices] = useState<string[]>([]);
  const [showDevices, setShowDevices] = useState(false);
  const [selectAllDevices, setSelectAllDevices] = useState(false);
  const [selectedDays, setSelectedDays] = useState<number[]>([]);
  const [receivedTime, setReceivedTime] = useState("");
  const [email, setEmail] = useState<string[]>([]);
  const [emailError, setEmailError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [reportHeader, setReportHeader] = useState("");
  const [showReportReceivedOn, setShowReportReceivedOn] = useState(false);
  const [showHelpMessage, setShowHelpMessage] = useState(false);

  const dropdownRef = useRef<HTMLDivElement>(null);
  const dropdownRef1 = useRef<HTMLDivElement>(null);
  const reportGeneratedType = "daily";
  const reportSelected = "meter_reading";
  const status = false;
  const reportGeneratedBy = users.user.name;

  const daysOfWeek = [
    { id: 1, name: "Monday" },
    { id: 2, name: "Tuesday" },
    { id: 3, name: "Wednesday" },
    { id: 4, name: "Thursday" },
    { id: 5, name: "Friday" },
    { id: 6, name: "Saturday" },
    { id: 7, name: "Sunday" },
  ];

  const navigate = useNavigate();

  useEffect(() => {
    const filtered = devices.filter((device) =>
      device.deviceName.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredDevices(filtered);
  }, [searchTerm, devices]);

  useEffect(() => {
    const fetchDataOverview = async () => {
      try {
        const response1 = (await http(`${baseUrl}/device?alldevice=1`)) as any;
        const devices1 = response1.devices || [];
        const deviceList1 = devices1
          .map((device: any) => ({
            deviceId: device.id,
            deviceName: device.deviceName,
            template: device.template,
          }))
          .filter((device: any) => device.deviceId && device.deviceName && device.template);
        const response2 = (await http(
          `${baseUrl}/assign-device?userId=${users.user.id}`
        )) as any;
        const devices2 = Array.isArray(response2.devices)
          ? response2.devices
          : Array.isArray(response2.devices?.devices)
          ? response2.devices.devices
          : [];
        const deviceList2 = devices2
          .map((device: any) => ({
            deviceId: device.Device.id,
            deviceName: device.Device.deviceName,
            template: device.Device.template,
          }))
          .filter((device: any) => device.deviceId && device.deviceName && device.template);
        const allDeviceNames = [...deviceList1, ...deviceList2];

        const uniqueDeviceNames = Array.from(new Set(allDeviceNames));
        
        
        setDevices(uniqueDeviceNames);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchDataOverview();
  }, [users.user.id]);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setShowDevices(false);
      }
      if (
        dropdownRef1.current &&
        !dropdownRef1.current.contains(event.target as Node)
      ) {
        setShowReportReceivedOn(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  

  const handleToggleDevices = () => {
    setShowDevices(!showDevices);
  };

  const handleTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setReceivedTime(event.target.value);
  };

  const handleFocus = () => {
    setShowHelpMessage(true);
  };

  const handleBlur = () => {
    setShowHelpMessage(false);
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const emailValue = event.target.value;
    const emailArray = emailValue
      .replace(/\s+/g, "")
      .split(",")
      .map((email) => email.trim());
    setEmail(emailArray);
    validateEmail(emailArray);
  };

  const validateEmail = (emailArray: string[]) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const invalidEmails = emailArray.filter((email) => !emailRegex.test(email));
    if (invalidEmails.length > 0) {
      setEmailError(`Invalid email(s): ${invalidEmails.join(", ")}`);
    } else {
      setEmailError("");
    }
  };

  const validateHeader = () => {
    if (reportHeader === "") {
      toast.error("Please enter report header");
      return false;
    }
    return true;
  };

  const validateStartDate = () => {
    if (method === "manually" && startDate === null) {
      return false;
    }
    return true;
  };
  const validateEndDate = () => {
    if (method === "manually" && endDate === null) {
      return false;
    }
    if (startDate !== null && endDate !== null && endDate <= startDate) {
      return false;
    }
    return true;
  };
  const validateDevices = () => {
    if (selectedDevices.length === 0) {
      return false;
    }
    return true;
  };
  const validateReportReceivedOn = () => {
    if (method === "autogenerated") {
      if (!Array.isArray(selectedDays) || selectedDays.length === 0) {
        return false;
      }
    }
    return true;
  };
  const validateReceivedTime = () => {
    if (!receivedTime) {
      return false;
    }
    return true;
  };

  const handleDeviceSelection = (device: any) => {
    if(selectedDeviceTemplate.includes(device.template)){
      setSelectedDevicesTemplate(selectedDeviceTemplate.filter((template) => template !== device.template))

    } else{
      setSelectedDevicesTemplate([...selectedDeviceTemplate, device.template])
    }
    if (selectedDevices.includes(device.deviceId)) {
      setSelectedDevices(
        selectedDevices.filter((id) => id !== device.deviceId)
      );
    } else {
      setSelectedDevices([...selectedDevices, device.deviceId]);
    }
  };

  const handleSelectAllDevices = (checked: boolean) => {
    if (checked) {
      setSelectedDevices(devices.map((device) => device.deviceId));
      setSelectedDevicesTemplate(devices.map((device) => device.template));
    } else {
      setSelectedDevices([]);
      setSelectedDevicesTemplate([]);
    }
    setSelectAllDevices(checked);
  };

  const handleDaySelection = (dayId: number) => {
    if (selectedDays.includes(dayId)) {
      setSelectedDays(selectedDays.filter((id) => id !== dayId));
    } else {
      setSelectedDays([...selectedDays, dayId]);
    }
  };

  const handleSelectAll = () => {
    if (selectedDays.length === daysOfWeek.length) {
      setSelectedDays([]);
    } else {
      setSelectedDays(daysOfWeek.map((day) => day.id));
    }
  };

  

  const handleReset = () => {
    setStartDate(null);
    setEndDate(null);
    setReportHeader("");
    setSelectedDays([]);
    setReportFormat("excel");
    setMethod("manually");
    setSelectedDevices([]);
    setSelectedDevicesTemplate([])
    setEmail([]);
    setReceivedTime("");
  };



  const handleDesabled = () => {
    if (method === "manually") {
      toast.info("Wait until the report is Downloaded");
    } else {
      toast.info("Wait until the report is Scheduled");
    }
  };

  const handleClick = async () => {
    const isStartDateValid = validateStartDate();
    const isEndDateValid = validateEndDate();
    const isDevicesValid = validateDevices();
    const isReportReceivedOnValid = validateReportReceivedOn();
    const isReceivedTimeValid = validateReceivedTime();
    const isEmailValid = emailError === "";
    const isHeaderValid = validateHeader();

    if (!isDevicesValid) {
      toast.error("Please select the devices.");
      return;
    }

    if (!isHeaderValid) {
      toast.error("Please enter Header");
      return;
    }
    if (method === "manually") {
      if (!isStartDateValid) {
        toast.error("Please fill the start date.");
        return;
      }

      if (!isEndDateValid) {
        if (endDate === null) {
          toast.error("Please fill the end date.");
        } else if (endDate <= startDate) {
          toast.error("End Date must be greater than Start Date.");
        }
        return;
      }
    }

    if (method === "autogenerated") {
      if (!isReportReceivedOnValid) {
        toast.error("Please select the report received on.");
        return;
      }

      if (!isReceivedTimeValid) {
        toast.error("Please select the received time.");
        return;
      }

      if (!isEmailValid) {
        toast.error("Please enter a valid email.");
        return;
      }
    }
    

    if(selectedDeviceTemplate.includes(14) && reportFormat === "pdf"){
      toast.error("Cannot generate PDF report for Energy Template");
      return;
    }
    if(selectedDeviceTemplate.includes(25) && reportFormat === "pdf"){
      toast.error("Cannot generate PDF report for DG Template");
      return;
    }
    if(selectedDeviceTemplate.includes(26) && reportFormat === "pdf"){
      toast.error("Cannot generate PDF report for Solar Template");
      return;
    }
    if(selectedDeviceTemplate.includes(38) && reportFormat === "pdf"){
      toast.error("Cannot generate PDF report for Energy Monitoring");
      return;
    }

    setLoading(true);
    const data = {
      customerId: users.user.customerId,
      reportGeneratedBy,
      reportHeader: reportHeader ? reportHeader : "Summary",
      reportMethod: method,
      reportFormat,
      reportGeneratedType,
      reportSelected,
      reportReceivedOn: selectedDays,
      reportReceivedTime: receivedTime,
      reportSelectedDevice: selectedDevices,
      email,
      status,
      startDate,
      endDate,
    };

    try {
      if (data.reportMethod === "autogenerated") {
        try {
          if (users.user.role === 1) {
            toast.info("Your Role can't Schedule Reports");
          } else {
            const response: any = await http(
              baseUrl + "/report-schedule",
              "POST",
              data
            );
            // addNewReport(dispatch, { report: response, totalItems: report.totalItems })
            // navigate("/home/report-list")
            toast.success("Report scheduled successfully");
            handleReset();
            navigate(`/report-table`);
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        const response = await fetch(baseUrl + "/report-download" || "", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        });
        if (data.reportFormat !== "pdf") {
          if (response.ok) {
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.style.display = "none";
            a.href = url;
            a.download = `${reportHeader ? reportHeader : "report"}.xlsx`;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
          } else {
            console.error("Failed to download file");
          }
        } else {
          if (response.ok) {
            const blob = await response.blob();
            const downloadUrl = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = downloadUrl;
            a.download = `${reportHeader ? reportHeader : "report"}.pdf`;
            document.body.appendChild(a);
            a.click();
            a.remove();
          } else {
            console.error("Error generating PDF");
          }
        }
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  

  return (
    <div className="px-4 mt-6 flex justify-center items-center">
      <div className="border w-full px-2 py-4 rounded-md mb-[200px]">
        <div className="font-inter p-2 text-xl font-bold leading-5 tracking-wider text-left text-gray-800">
          Meter Reading Data Report Config
        </div>
        <div className="mx-2 mt-2">
          <div className="grid grid-cols-4 justify-items-start mt-4 gap-4">
            <div className="w-full">
              <p className="text-[#6F7482] font-plex-sans text-base font-normal leading-6 tracking-tight text-left">
                Select Report Format
              </p>
              <div className="mt-2">
                <select
                  className="block w-full px-4 py-4 pr-8 leading-tight bg-[#F8FAFC] border border-gray-300 rounded-lg"
                  value={reportFormat}
                  onChange={(e) => {
                    setReportFormat(e.target.value);
                    setSelectedDevices([]);
                    setSelectedDevicesTemplate([]);
                    setSelectedDays([]);
                  }}
                >
                  <option value="selectReportFormat">
                    Select Report Format
                  </option>
                  <option value="excel">Excel</option>
                  <option value="pdf">PDF</option>
                </select>
              </div>
            </div>
            <div className="w-full">
              <p className="text-[#6F7482] font-plex-sans text-base font-normal leading-6 tracking-tight text-left">
                Method
              </p>
              <div className="mt-2">
                <select
                  className="block w-full px-4 py-4 pr-8 leading-tight bg-[#F8FAFC] border border-gray-300 rounded-lg"
                  value={method}
                  onChange={(e) => setMethod(e.target.value)}
                >
                  <option value="manually">Manual</option>
                  <option value="autogenerated">Auto-Generated</option>
                </select>
              </div>
            </div>
            <div className="w-full col-span-2">
              <p className="text-[#6F7482] font-plex-sans text-base font-normal leading-6 tracking-tight text-left">
                Report Header
              </p>
              <div className="mt-2">
                <input
                  type="text"
                  value={reportHeader}
                  onChange={(e) => setReportHeader(e.target.value)}
                  className="block w-full px-4 py-4 pr-8 leading-tight bg-[#F8FAFC] border border-gray-300 rounded-md"
                  placeholder="Report Header"
                />
              </div>
            </div>

            {method === "autogenerated" && (
              <>
                <div className="w-full" ref={dropdownRef1}>
                  <p className="text-[#6F7482] font-plex-sans text-base font-normal leading-6 tracking-tight text-left">
                    Report Received On
                  </p>
                  <div className="mt-2 relative">
                    <button
                      className="bg-[#F8FAFC] hover:bg-gray-100 text-gray-800 py-4 px-4 w-full text-left border border-gray-300 rounded-lg dropdown-arrow"
                      onClick={() =>
                        setShowReportReceivedOn(!showReportReceivedOn)
                      }
                    >
                      {selectedDays.length > 0
                        ? selectedDays
                            .map(
                              (dayId) =>
                                daysOfWeek.find((day) => day.id === dayId)?.name
                            )
                            .join(", ")
                        : "Select Days"}
                    </button>
                    {showReportReceivedOn && (
                      <div className="absolute z-10 bg-white border border-gray-400 rounded shadow w-full max-h-60 overflow-y-auto">
                        <ul>
                          <li className="py-2 px-4 hover:bg-gray-100">
                            <input
                              type="checkbox"
                              checked={
                                selectedDays.length === daysOfWeek.length
                              }
                              onChange={handleSelectAll}
                            />
                            <span className="ml-2">Select All</span>
                          </li>
                          {daysOfWeek.map((day) => (
                            <li
                              key={day.id}
                              className="py-2 px-4 hover:bg-gray-100"
                            >
                              <input
                                type="checkbox"
                                checked={selectedDays.includes(day.id)}
                                onChange={() => handleDaySelection(day.id)}
                              />
                              <span className="ml-2">{day.name}</span>
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
                <div className="w-full">
                  <label
                    className="text-[#6F7482] font-plex-sans text-base font-normal leading-6 tracking-tight text-left"
                    htmlFor="r-time"
                  >
                    Received Time
                  </label>
                  <TextField
                    type="time"
                    id="r-time"
                    name="receivedTime"
                    value={receivedTime}
                    onChange={handleTimeChange}
                    variant="outlined"
                    className="border border-gray-300 rounded-md p-[0.4rem] w-full"
                    sx={{marginTop: "8px"}}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
                <div className="w-full col-span-2">
                  <div>
                    <label
                      className="text-[#6F7482] font-plex-sans text-base font-normal leading-6 tracking-tight text-left"
                      htmlFor="email"
                    >
                      Email
                    </label>
                    <input
                      type="text"
                      id="email"
                      value={email.join(", ")}
                      onChange={handleEmailChange}
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                      className="border border-gray-300 rounded-md p-[0.4rem] py-[15px] w-full"
                      style={{marginTop: "8px"}}
                    />
                    {showHelpMessage && (
                      <p className="text-gray-500 text-sm mt-1">
                        use comma (,) to add multiple email ids
                      </p>
                    )}
                    {emailError && (
                      <p className="text-red-500 text-sm mt-1">{emailError}</p>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>

          <div className="mt-4">
            {method === "manually" && (
              <>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <div className="grid grid-cols-2 justify-items-start mt-4 gap-4 w-full">
                    <div className="w-full">
                      <p className="text-[#6F7482] mb-2 font-plex-sans text-base font-normal leading-6 tracking-tight text-left">
                        Start Date
                      </p>
                      <DateTimePicker
                        value={startDate}
                        onChange={(e: any) => setStartDate(e)}
                        className="w-[100%] bg-[#F8FAFC] "
                      />
                    </div>
                    <div className="w-full">
                      <p className="text-[#6F7482] mb-2 font-plex-sans text-base font-normal leading-6 tracking-tight text-left">
                        End Date
                      </p>
                      <DateTimePicker
                        value={endDate}
                        onChange={(e: any) => setEndDate(e)}
                        className="w-[100%] bg-[#F8FAFC] mt-2"
                      />
                    </div>
                  </div>
                </LocalizationProvider>
              </>
            )}
          </div>

          <div className="grid grid-cols-2 gap-4 mt-4">
            <div className="w-full mt-4" ref={dropdownRef}>
              <p className="text-[#6F7482] font-plex-sans text-base font-normal leading-6 tracking-tight text-left">
                Select Devices
              </p>
              <div className="mt-2">
                <div className="relative">
                  <button
                    className="bg-[#F8FAFC] hover:bg-gray-100 text-gray-800 py-4 px-4 w-full text-left border border-gray-300 rounded-lg dropdown-arrow"
                    onClick={handleToggleDevices}
                  >
                    {selectedDevices.length > 0
                      ? selectedDevices
                          .map(
                            (id) =>
                              devices.find((device) => device.deviceId === id)
                                ?.deviceName
                          )
                          .join(", ")
                      : "Select Devices"}
                  </button>
                  {showDevices && (
                    <div className="absolute z-10 bg-white border border-gray-400 rounded shadow w-full max-h-60 overflow-y-auto">
                      <div className="sticky top-0 bg-white p-2">
                        <input
                          type="text"
                          placeholder="Search devices..."
                          value={searchTerm}
                          onChange={(e) => setSearchTerm(e.target.value)}
                          className="w-full p-2 border rounded"
                        />
                      </div>
                      <ul>
                        <li className="py-2 px-4 hover:bg-gray-100 sticky top-10 bg-white">
                          <input
                            type="checkbox"
                            checked={selectAllDevices}
                            onChange={(e) =>
                              handleSelectAllDevices(e.target.checked)
                            }
                          />
                          <span className="ml-2">Select All Devices</span>
                        </li>
                        {filteredDevices.map((device: any, index: number) => (
                          <li
                            key={index}
                            className="py-2 px-4 hover:bg-gray-100"
                          >
                            <input
                              type="checkbox"
                              checked={selectedDevices.includes(
                                device.deviceId
                              )}
                              onChange={() => handleDeviceSelection(device)}
                            />
                            <span className="ml-2">{device.deviceName}</span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div></div>
          </div>

          <div className="flex justify-end mt-20 gap-4">
            <div
              className="relative p-2 gap-8 rounded-md bg-[#E0E0E0] cursor-pointer text-black"
              onClick={handleReset}
            >
              <span className="text-black px-2">Reset</span>
            </div>
            <div
              className={`relative p-2 gap-8 rounded-md ${
                loading
                  ? "bg-gray-400 cursor-default"
                  : "bg-[green] cursor-pointer"
              }`}
              onClick={!loading ? handleClick : handleDesabled}
            >
              <span className="text-white">
                {loading
                  ? method === "autogenerated"
                    ? "Scheduling..."
                    : "Downloading..."
                  : method === "autogenerated"
                  ? "Schedule"
                  : "Download"}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MeterReadingData1;
