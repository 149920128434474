import  {useEffect, useState, useMemo} from "react";
import Table from "@mui/material/Table";
import Box from "@mui/material/Box";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { EnhancedTableToolbar } from "./EnhancedTableToolBar";
import { EnhancedTableHead } from "./EnhancedTableHead";
import { UserOnboard, UserOnboardApiResponse } from "../../../types/UserOnboardTypes";
import { ConfirmModal } from "../../../utils/alertui/ConfirmModal";
import AddUserForm from "./AddUserForm";
import LongMenu from "../../../utils/longMenu/LongMenu";
import { useUserOnBoardState } from "../../../app-context/user-context/UserState";
import { http } from "../../../helper/http";
import { UserApiResponse } from "../../../types/UserTypes";
import { toast } from "react-toastify";
import { getUserPageClick, setUserOnbaord } from "../../../app-context/user-context/UserAction";
import { fetchAllCustomer } from "../../../common-function/CommonFunction";
import { setAllCustomer } from "../../../app-context/customer-context/CustomerAction";
import { useCustomerState } from "../../../app-context/customer-context/CustomerState";

const baseUrl = process.env.REACT_APP_BASE_URL;
export default function Customers() {
  const [page] = useState(0);
  const [rowsPerPage] = useState(10);
  const [updatedRows, setUpdatedRows] = useState<UserOnboard[]>([]);
  const [editModal, setEditModal] = useState(false);
  //this customerData for setEdit data while editing 
  // const [customerData] = useState<UserOnboard[]>([]);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const {userOnBoard, dispatch}=useUserOnBoardState()
  const [deleteId, setDeleteId]=useState(0)
  const [userData, setUserData] = useState<UserOnboard[]>([]);
  const {  dispatch: customerDispatch } = useCustomerState();

  

  useEffect(() => {
    setUpdatedRows(userOnBoard.users);    
  }, [userOnBoard, userOnBoard.users]);

  const handlePageChange = async (newPage: number) => {
    const direction = newPage > userOnBoard.currentPage - 1 ? "next" : "previous";
    const page = +userOnBoard.currentPage;
    try {
      const resp = (await http(
        baseUrl + `/user?page=${direction === "next" ? page + 1 : page - 1}`
      )) as any;
      const newCustomers = resp.users;
      // getCustomerPageClick(dispatch, newCustomers);
      getUserPageClick(dispatch, newCustomers)
    } catch (err) {
      console.error(err);
    }
  };



  const toggleCustomerForm = () => {
    setEditModal((prevVal) => !prevVal);
  };
  const emptyRows = 10 - updatedRows.length;

  const visibleRows = useMemo(
    () =>
      updatedRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [page, rowsPerPage, updatedRows]
  );

  const handleMenuOptionClick = async (option: string, id: number) => {
    try {
      switch (option) {
        case "Delete":
          setShowConfirmModal(true);
          setDeleteId(id);
          break;
        case "Edit":
          const resp = (await fetchAllCustomer()) as any;
          setAllCustomer(customerDispatch, resp.customers);
          const editResp = (await http(
            baseUrl + `/user?id=${id}`
          )) as UserOnboardApiResponse;          
          const users = editResp.users;
          setUserData([users]);
          toggleCustomerForm();
          break;
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleConfirmDelete = async () => {
    if (deleteId !== null) {
      try {
        const deleteResp = (await http(
          baseUrl + `/user?id=${deleteId}`,
          "DELETE"
        )) as UserApiResponse;
        toast.success(deleteResp.message)
        if (userOnBoard.users.length === 1) {
          setUserOnbaord(dispatch, 1);
        } else {
          setUserOnbaord(dispatch, userOnBoard.currentPage);
        }
      } catch (err: any) {
        // console.log();
        toast.error(err.message)
      }
    }
    setShowConfirmModal(false)
  };

  return (
    <>
      <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <Paper sx={{ width: "99%" }}>
          <EnhancedTableToolbar />
          <div className="h-[72vh] overflow-y-auto">
            <TableContainer>
              {showConfirmModal && (
                <ConfirmModal
                  title="Confirmation"
                  message="Are you sure you want to delete?"
                  onConfirm={handleConfirmDelete}
                  onCancel={() => {
                    setShowConfirmModal(false);
                  }}
                  isVisible={showConfirmModal}
                />
              )}
              <Table
                className="min-w-750 bg-white"
                aria-labelledby="tableTitle"
                size="medium"
              >
                {editModal && (
                  <AddUserForm
                    uniqueId="EDIT"
                    data={userData}
                    toggleForm={editModal}
                    toggleFormFun={toggleCustomerForm}
                  />
                )}
                <EnhancedTableHead />
                <TableBody>
                  {visibleRows.map((row) => (
                    <TableRow
                      key={row?.id || null}
                      hover
                      className="cursor-pointer"
                    >
                      {row && (
                        <>
                          <TableCell align="center" className="py-3">
                            {row.name}
                          </TableCell>
                          <TableCell align="center" className="py-3">
                            {row.email}
                          </TableCell>
                          <TableCell
                            className="text-center"
                            style={{ textAlign: "center" }}
                          >
                            <LongMenu
                              options={["Edit","Delete"]}
                              onOptionClick={handleMenuOptionClick}
                              id={row.id}
                            />
                          </TableCell>
                        </>
                      )}
                    </TableRow>
                  ))}
                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: (45) * emptyRows,
                      }}
                    >
                      <TableCell colSpan={4} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <TablePagination
            rowsPerPageOptions={[]}
            component="div"
            count={userOnBoard?.totalItems || 0}
            rowsPerPage={10}
            page={Math.max(
              0,
              Math.min(userOnBoard?.currentPage - 1, userOnBoard?.totalPages - 1)
            )}
            onPageChange={(event, newPage) => handlePageChange(newPage)}
            className="bg-gray-100"
          />
        </Paper>
      </Box>
    </>
  );
}
