import { useEffect, useRef, useState } from "react";
import CardTwo from "../../components/dashBoardComponents/CardTwo";
import { http } from "../../helper/http";
import {
  FormControl,
  InputLabel,
  MenuItem,
  MenuProps,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import LineChart from "../../components/apex-chart/LineChart";
import CardFour from "../../components/dashBoardComponents/CardFour";
import MainDashboardTotalizerHumidity from "./loginRedirectionDashboard/MainDashboardTotalizerHumidity";

const baseUrl = process.env.REACT_APP_BASE_URL;
function UserDashboard() {
  const [templates, setTemplates] = useState<any>([]);
  const [selectedTemplateId, setSelectedTemplateId] = useState<number | "">("");

  const menuProps: Partial<MenuProps> = {
    PaperProps: {
      style: {
        maxHeight: 400,
        overflowY: "auto",
      },
    },
  };

  const fetchData = async () => {
    let response: any = null;
    try {
      response = await http(baseUrl + `/templates?alltemplates=a`);
    } catch (error) {
      console.error(error);
    }
    setTemplates(response.template);
  };

  const handleChange = (event: SelectChangeEvent<number | "">) => {
    setSelectedTemplateId(event.target.value as number);
  };



  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (templates.length > 0) {
      setSelectedTemplateId(templates[0].id);
    }
  }, [templates]);

  return (
    <>
      <h1 className="px-2 mb-5" style={{ fontSize: "28px" }}>
      Ambient Monitoring Dashboard
      </h1>
      <div className="lg:w-1/2 sm:w-3/5 w-full" style={{display: "none"}}>
        <FormControl fullWidth>
          <InputLabel id="template-select-label">Select Template</InputLabel>
          <Select
            labelId="template-select-label"
            id="template-select"
            value={selectedTemplateId}
            label="Select Template"
            onChange={handleChange}
            MenuProps={menuProps}
          >
            {templates?.map((template: any) => (
              <MenuItem key={template.id} value={template.id}>
                {template.templatename}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div className="mt-5">
        {selectedTemplateId === 20 && (
            <MainDashboardTotalizerHumidity selectedTemplateId={selectedTemplateId} />
        )}
      </div>
    </>
  );
}

export default UserDashboard;
