import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  DialogActions,
} from "@mui/material";
import { http } from "../../../helper/http";
import { toast } from "react-toastify";

const baseUrl = process.env.REACT_APP_BASE_URL;
const Rader = ({ deviceId, onClose }: any) => {
  const [radarId, setRadarId] = useState("");
  const [unitCode, setUnitCode] = useState("");
  const [tankCapacity, setTankCapacity] = useState("");
  const [bottonText, setBottonText] = useState("Save");

  const fetchData = async () => {
    let response: any = null;
    try {
      response = await http(baseUrl + `/radar?deviceId=${deviceId}`);
      console.log("response", response);
      setRadarId(response.vatCode);
      setUnitCode(response.unitCode);
      setTankCapacity(response.bl);
    } catch (error) {
      console.error(error);
    }
    response ? setBottonText("Update") : setBottonText("Save");
  };

  const handleSave = async () => {
    const body = {
      deviceId,
      vatCode: radarId,
      unitCode,
      bl: tankCapacity, 
    }
    try {
      await http(baseUrl + `/radar`,
        "PUT",
        body
      );
      toast.success(`Radar ${bottonText}d Successfully`)
      onClose();
    } catch (error) {
      toast.error(`Faild to ${bottonText}`);
      console.error(error);
      
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <TextField
        label="Radar Id"
        value={radarId}
        onChange={(e) => setRadarId(e.target.value)}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Unit Code"
        value={unitCode}
        onChange={(e) => setUnitCode(e.target.value)}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Tank Radius"
        value={tankCapacity}
        onChange={(e) => setTankCapacity(e.target.value)}
        fullWidth
        margin="normal"
      />
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary">
          {bottonText}
        </Button>
      </DialogActions>
    </>
  );
};

export default Rader;
