import { jwtDecode } from 'jwt-decode'
import ProtectedComponent from '../../utils/protected-route/ProtectedComponent'
import AdminDashboard1 from './AdminDashboard1'
import CustomerDashboard from './CustomerDashboard'
import Overviewpage from './Overviewpage'
import UserDashboard from './DynamicDashboard'
const DashBoard = () => {
  const token:any = window.localStorage.getItem('token')
  const decodedToken = jwtDecode<any>(token);
  // console.log("deconded", decodedToken);
  
  return (
    <>
      <ProtectedComponent>
        <AdminDashboard1 />
      </ProtectedComponent>
      <ProtectedComponent roleids={[2]}>
        {decodedToken.loginRedirectPage === "dashboard" ? <UserDashboard /> : <Overviewpage />}
        {/* <DashbaordWater /> */}
        {/* <CustomerDashboard /> */}
        {/* <Overviewpage /> */}
        
      </ProtectedComponent>
    </>
  )
}

export default DashBoard